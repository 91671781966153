// import the required animation functions from the angular animations module
import { trigger, state, animate, transition, style, query, group } from '@angular/animations';

export const SlideLeftAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    trigger('SlideLeftAnimation', [

        transition('* => *', [

            query(':leave', style({ position: 'absolute', left: 0, right: 0, transform: 'translate3d(0%,0,0)' }), { optional: true }),
            query(':enter', style({ position: 'absolute', left: 0, right: 0, transform: 'translate3d(-100%,0,0)' }), { optional: true }),
            group([
                query(':leave', group([
                    animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translate3d(100%,0,0)' })), // y: '-100%'
                ]), { optional: true }),

                query(':enter', group([
                    animate('500ms cubic-bezier(.35,0,.25,1)', style({ transform: 'translate3d(0%,0,0)' })),
                ]), { optional: true })

            ])

        ])

        // Or with more of a delay
        // transition('* <=> *', [
        //     // Initial state of new route
        //     query(':enter',
        //       style({
        //         position: 'fixed',
        //         width:'100%',
        //         transform: 'translateX(-100%)'
        //       }),
        //       {optional:true}),

        //     // move page off screen right on leave
        //     query(':leave',
        //       animate('500ms ease',
        //         style({
        //           position: 'fixed',
        //           width:'100%',
        //           transform: 'translateX(100%)'
        //         })
        //       ),
        //     {optional:true}),

        //     // move page in screen from left to right
        //     query(':enter',
        //       animate('500ms ease',
        //         style({
        //           opacity: 1,
        //           transform: 'translateX(0%)'
        //         })
        //       ),
        //     {optional:true}),
        //   ])


    ]);
