import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    standalone: true,
})
export class FilterPipe implements PipeTransform {

    transform(items: any[], field: string, filter: string, isNumber: boolean): any {
        if (!items || !filter)
            return items;

        if (isNumber && isNumber === true)
            return items.filter(item => item[field] === parseFloat(filter));
        else
            return items.filter(item => item[field].toLowerCase().indexOf(filter.toLowerCase()) !== -1);
    }

}
