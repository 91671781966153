import { HttpClient } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { WebsocketService } from '@nevtec/services/web-socket.service';

import { BaseArrayResponseDto } from '../../../../../rest-api/src/shared/base/base-array-response.dto';
import { NotificationDto } from './../../../../../rest-api/src/notification/dto/notification.dto';
import { SocketEventNames } from '../../../../../rest-api/src/socket/dto';

@Injectable({ providedIn: 'root' })
export class NotificationsService {

    private destroyRef = inject(DestroyRef);
    private _notifications: ReplaySubject<NotificationDto[]> = new ReplaySubject<NotificationDto[]>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _webSocketService: WebsocketService,
    ) {
        this._webSocketService.onEvent<NotificationDto>(SocketEventNames.NewNotification)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((notification: NotificationDto) => {
                // Handle the new notification event
                this.getAll().subscribe();
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<NotificationDto[]> {
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(): Observable<NotificationDto[]> {
        return this._httpClient.get<BaseArrayResponseDto<NotificationDto>>('/notification', { params: { sort: '-date_entered' } }).pipe(
            map((response: BaseArrayResponseDto<NotificationDto>) => response.records),
            tap((notifications) => {
                this._notifications.next(notifications);
            }),
        );
    }

    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: NotificationDto): Observable<NotificationDto> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<NotificationDto>('/notification', { notification }).pipe(
                map((newNotification) => {
                    // Update the notifications with the new notification
                    this._notifications.next([...notifications, newNotification]);

                    // Return the new notification from observable
                    return newNotification;
                }),
            )),
        );
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(id: string, notification: NotificationDto): Observable<NotificationDto> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.patch<NotificationDto>(`/notification/${id}`, notification).pipe(
                map((updatedNotification: NotificationDto) => {
                    // Find the index of the updated notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Update the notification
                    notifications[index] = updatedNotification;

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated notification
                    return updatedNotification;
                }),
            )),
        );
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.delete<boolean>(`/notification/${id}`).pipe(
                map((isDeleted: boolean) => {
                    // Find the index of the deleted notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Delete the notification
                    notifications.splice(index, 1);

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.get<boolean>('/notification/mark-all-as-read').pipe(
                map((isUpdated: boolean) => {
                    // Go through all notifications and set them as read
                    notifications.forEach((notification, index) => {
                        notifications[index].read = true;
                    });

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated status
                    return isUpdated;
                }),
            )),
        );
    }
}
