import { trigger, animate, state, style, transition } from '@angular/animations';

/* ======================================================
USAGE

<div id="test" [@AccordionAnimation]="showCardBody ? 'open' : 'closed'">test<br />test<br />test<br />test<br />test<br />test<br />test<br />test<br />test<br />test<br />test<br />test<br /></div>
====================================================== */

export const AccordionAnimation =

    trigger('AccordionAnimation', [
        state('open', style({
            height: '*',
            opacity: 1,
        })),
        state('closed', style({
            height: '0',
            opacity: 0
        })),
        transition('open => closed', [
            animate('0.2s')
        ]),
        transition('closed => open', [
            animate('0.2s')
        ]),
    ]);
