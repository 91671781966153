import { Injectable, OnDestroy } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from 'environments/environment';
import { Subject, Observable } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService } from 'app/core/auth/auth.service';

import { NotificationDto } from '../../../rest-api/src/notification/dto/notification.dto';
import { SocketEventNames, SocketMessageDto } from '../../../rest-api/src/socket/dto';
// import { SwalService } from './swal.service';

@Injectable({
    providedIn: 'root'
})
export class WebsocketService implements OnDestroy {
    private socket: Socket | null = null;
    public messages: Subject<any> = new Subject<any>();

    constructor(
        private matSnackBar: MatSnackBar,
        private authService: AuthService,
        // private swalService: SwalService,
    ) {
        this.connect();
    }

    private connect(): Promise<void> {
        const token = this.authService.accessToken;

        return new Promise<void>((resolve, reject) => {
            if (!this.socket) {
                const urlWithToken = `${environment.webSocketUrl}?token=${token}`;
                // console.log('urlWithToken', urlWithToken);
                this.socket = io(urlWithToken);

                this.socket.on('connect', () => {
                    console.log('WebSocket connected');
                    resolve();
                });

                this.socket.on('connect_error', (error) => {
                    console.error('WebSocket connection error:', error);
                    reject(error);
                });

                this.socket.on('disconnect', () => {
                    console.log('WebSocket disconnected');
                });

                this.socket.on('message', (data) => {
                    this.messages.next(data);
                });
            } else if (this.socket.connected) {
                resolve();
            } else {
                this.socket.connect();
                this.socket.once('connect', resolve);
                this.socket.once('connect_error', reject);
            }
        });
    }

    disconnect(): void {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
            console.log('WebSocket manually disconnected');
        }
    }

    private evaluateSnackbar(event: SocketEventNames, data: any): void {
        if (event === SocketEventNames.NewNotification && data && (data as SocketMessageDto).payload) {
            const notification = (data as SocketMessageDto).payload as NotificationDto;
            this.matSnackBar.open(`A new notification: ${notification.title} - ${notification.description}`, 'Close', {
                duration: 5000,
            });
        }
    }

    onEvent<T>(event: SocketEventNames): Observable<T> {
        return new Observable<T>((observer) => {
            if (!this.socket) {
                // console.error('WebSocket is not connected');
                observer.error(new Error('WebSocket is not connected'));
                return;
            }

            const handler = (data: T) => {
                // console.log(`Event received: ${event}`, data);
                this.evaluateSnackbar(event, data);
                observer.next(data);
            };

            // console.log(`Attaching listener for event: ${event}`);
            this.socket.on(event, handler);

            // Cleanup on unsubscription
            return () => {
                // console.log(`Removing listener for event: ${event}`);
                if (this.socket) {
                    this.socket.off(event, handler);
                }
            };
        });
    }

    sendMessage(message: SocketMessageDto): void {
        if (this.socket && this.socket.connected) {
            const event = message.event_type;
            if (event) {
                this.socket.emit(event, message);
                // console.log(`Message sent to WebSocket: ${event}`, message);
            } else {
                console.error('Event type is missing in message.');
            }
        } else {
            console.error('Unable to send message. WebSocket is not connected.');
        }
    }

    ngOnDestroy(): void {
        this.disconnect();
    }
}
