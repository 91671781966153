import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap, pluck, take, switchMap } from 'rxjs';

import { AppStateService } from '@nevtec/services/app-state.service';
import { User } from 'app/core/user/user.types';
// import { UserGroupExternal } from '../../../../rest-api/src/user/enums/user-group-external.enum';
// import { UserGroupInternal } from '../../../../rest-api/src/user/enums/user-group-internal.enum';
import { UserRole } from '../../../../rest-api/src/user/enum/user-role.enum';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    public location_id$: Observable<string> = this.user$
        .pipe(take(1), pluck('location_id'));

    public isGroup$(group: string): Observable<boolean> {
        return this.user$
            .pipe(
                take(1),
                map((user: User) => user.group.includes(group)),
            );
    }
    public isRole$(role: string): Observable<boolean> {
        return this.user$
            .pipe(
                take(1),
                map((user: User) => user.role.includes(role)),
            );
    };
    public role$: Observable<UserRole> = this.user$.pipe(
        map((user: User) => user.role as UserRole)
    );

    // public isExternal$ = this.isRole$(UserRole.External);
    // public isInternal$ = this.isRole$(UserRole.Internal);
    public isSuperAdmin$ = this.isRole$(UserRole.SuperAdmin);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _appStateService: AppStateService,
    ) {
        // this.user$.subscribe((user: User) => {
        //     console.log(user)
        // });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        if (value)
            this._appStateService.set('user', value);
    }

    get user$(): Observable<User> {
        return this._appStateService.get$('user');
    }

    get user_id(): string {
        return this._appStateService.get('user')._id;
    }

    settings$ = this.user$
        .pipe(
            map((user: User) => user.settings),
        )

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {
        return this._httpClient.get<User>('/user/profile');
        // return this._httpClient.get<User>('api/common/user').pipe(
        //     tap((user) => {
        //         this._user.next(user);
        //     })
        // );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }

    findName(user_id: string): Observable<any> {
        return this._httpClient.get(`/user/findName/${user_id}`);
    }

    updateSetting(setting: {}): Observable<User> {
        return this._httpClient
            .patch('/user/setting', setting)
            .pipe(tap((user: User) => this._appStateService.set('user', user)));
    }

    updateSettings(settings: any[]): Observable<User> {
        return this._httpClient
            .patch('/user/settings', settings)
            .pipe(tap((user: User) => this._appStateService.set('user', user)));
    }

}
