import { trigger, animate, transition, style, query, stagger, group } from '@angular/animations';

export const ListStaggerAnimation =

    trigger('ListStaggerAnimation', [
        transition('* <=> *', [
            query(
                ':enter',
                [
                    style({ opacity: 0, transform: 'translateY(-15px)' }),
                    stagger(
                        '100ms',
                        animate(
                            '750ms ease-out',
                            style({ opacity: 1, transform: 'translateY(0px)' })
                        )
                    )
                ],
                { optional: true }
            ),
            query(':leave', animate('100ms', style({ opacity: 0 })), {
                optional: true
            })
        ])
    ]);