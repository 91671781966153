import { Component } from '@angular/core';

import { CheckVersionService } from '@nevtec/modules/check-version/check-version.service';

@Component({
    selector: 'app-version',
    standalone: true,
    imports: [],
    template: `
        <div>
            v{{ version }}
        </div>
    `,
    styles: `
        :host {
            @apply flex items-center;
        }
    `,
})
export class VersionComponent {

    public version = this._checkVersionService.VERSION;

    constructor(
        private _checkVersionService: CheckVersionService,
    ) { }

}