// Like Partial<JwtPayload> and Partial<UserDto>

export interface SocketUserInfoDto {
    // account_id?: string;
    email?: string;
    // exp?: number;
    first_name?: string;
    // iat?: number;
    last_name?: string;
    sub?: string;
}