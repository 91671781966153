import { take } from 'rxjs/operators';
// https://sweetalert2.github.io/
// https://github.com/sweetalert2/ngx-sweetalert2

import { Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

import Swal, { SweetAlertResult, SweetAlertOptions, SweetAlertIcon } from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class SwalService {

    public Swal = Swal; // Use it directly



    constructor() { }



    public confirm(message: string, title?: string, extraOptions?: SweetAlertOptions): Observable<SweetAlertResult> {
        extraOptions = {
            ... {
                allowEscapeKey: false,
                allowOutsideClick: false,
                cancelButtonText: 'Cancel',
                icon: 'question',
                showCancelButton: true,
                showConfirmButton: true,
                showDenyButton: false,
            },
            ...extraOptions,
        };
        return this.open(message, title, extraOptions);
    }



    public error(message: string, title?: string, extraOptions?: SweetAlertOptions): Observable<SweetAlertResult> {
        extraOptions = {
            ... {
                confirmButtonColor: this.getColors().denyButtonColor,
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: true,
                showDenyButton: false,
            },
            ...extraOptions,
        };
        return this.open(message, title, extraOptions);
    }



    public getColors() {
        /* ======================================================
        Function, version a public variable, because we can't read
        this.colors when SwalService is created outside of the injector,
        like with the confirmable decorator...and it's a small
        adjustment - better than repeating the same values for
        a static variable and public variable
        ====================================================== */
        return {
            confirmButtonColor: 'var(--fuse-primary)',
            denyButtonColor: 'var(--fuse-warn)',
        };
    }



    public info(message: string, title?: string, extraOptions?: SweetAlertOptions): Observable<SweetAlertResult> {
        extraOptions = {
            ... {
                icon: 'info',
                showCancelButton: false,
                showConfirmButton: true,
                showDenyButton: false,
            },
            ...extraOptions,
        };
        return this.open(message, title, extraOptions);
    }



    public input(html: string, title?: string, extraOptions?: SweetAlertOptions): Observable<SweetAlertResult> {
        /* ======================================================
        You'll need to pass HTML of the fields you want to show - for the input you want to collect
        
        EX)
        `
        <label for="your-mfa-code" class="swal2-input-label">
            Please enter your verification code:
        </label>
        <input id="your-mfa-code" class="swal2-input" />

        <p class="my-3">
            <input type="checkbox" id="mfa-remember-me" checked="checked" value="true" />
            <label class="cursor-pointer" for="mfa-remember-me">Remember this device for 120 days</label>
        </p>
        `
        ====================================================== */
        extraOptions = {
            ... {
                allowEnterKey: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                html: html,
                showCancelButton: true,
            },
            ...extraOptions,
        };
        return this.open('', title, extraOptions);
    }



    public open(message: string, title?: string, extraOptions?: SweetAlertOptions): Observable<SweetAlertResult> {
        return new Observable<SweetAlertResult>(observer => {

            /* ======================================================
            error
            info
            question
            success
            warning
            ====================================================== */
            let hideClasses = [
                'animated',
                'animate_animated',
                'animate_faster',
                'animate_zoomOutUp',
                'shadow-2xl',
            ];

            let showClasses = [
                'animated',
                'animate_animated',
                'animate_faster',
                'animate_fadeInDown',
                'shadow-2xl',
            ];

            if (!title && extraOptions && extraOptions.toast) {
                /* ======================================================
                This will get rid of the space at the bottom and shrink
                the toast a little - but using .flex when there is a title makes alignment harder
                ====================================================== */
                hideClasses.push('flex');
                hideClasses.push('items-center');
                showClasses.push('flex');
                showClasses.push('items-center');
            }
            // console.log((SwalService.prototype.constructor as any).c)
            const options: SweetAlertOptions = {
                ... {
                    // cancelButtonColor: '',
                    confirmButtonColor: this.getColors().confirmButtonColor, // SCSS wasn't always working? So just manually override
                    denyButtonColor: this.getColors().denyButtonColor,
                    hideClass: {
                        popup: hideClasses.join(' '),
                    },
                    showClass: {
                        popup: showClasses.join(' '),
                    },
                    html: message, // or html or text
                    title: title,
                },
                ...extraOptions,
            };

            Swal.fire(options).then((result: SweetAlertResult) => {

                const cancelReason = ([
                    'backdrop',
                    'cancel',
                    'close',
                    'esc',
                    'timer',
                ].filter(reason => {
                    return (result.dismiss === Swal.DismissReason[reason]);
                })[0] || false);

                result = {
                    ...result,
                    ...{
                        cancelReason: cancelReason,
                    },
                };

                observer.next(result);
                observer.complete();
            });

        });
    }



    public simpleInput(message: string, title?: string, extraOptions?: SweetAlertOptions): Observable<SweetAlertResult> {
        extraOptions = {
            ... {
                allowEnterKey: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                input: 'text',
                showCancelButton: true,
            },
            ...extraOptions,
        };
        return this.open(message, title, extraOptions);
    }



    public success(message: string, title?: string, extraOptions?: SweetAlertOptions): Observable<SweetAlertResult> {
        extraOptions = {
            ... {
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: true,
                showDenyButton: false,
            },
            ...extraOptions,
        };
        return this.open(message, title, extraOptions);
    }



    public toast(type: SweetAlertIcon = 'success', message: string, title?: string, extraOptions?: SweetAlertOptions): Subscription {
        extraOptions = {
            ... {
                // didOpen: (toast) => {
                //     toast.addEventListener('mouseenter', Swal.stopTimer)
                //     toast.addEventListener('mouseleave', Swal.resumeTimer)
                // },
                hideClass: {
                    popup: 'animated animate__animated animate__fadeOut animate__faster shadow-2xl',
                },
                icon: type,
                position: 'top',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                toast: true,
            },
            ...extraOptions,
        };
        return this.open(message, title, extraOptions).subscribe();
    }



    public warning(message: string, title?: string, extraOptions?: SweetAlertOptions): Observable<SweetAlertResult> {
        extraOptions = {
            ... {
                icon: 'warning',
                showCancelButton: false,
                showConfirmButton: true,
                showDenyButton: false,
            },
            ...extraOptions,
        };
        return this.open(message, title, extraOptions);
    }



}
