import { inject } from '@angular/core';
import { catchError, from, Observable, throwError } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpResponse, HttpHandlerFn, HttpErrorResponse } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

import { Http } from '@capacitor-community/http';
import { Capacitor } from '@capacitor/core';

import { AuthService } from './auth.service';

export const capacitorInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    if (Capacitor.isNative && req.url.indexOf('./') === -1 && req.url.indexOf('api/') === -1) {
        const authService = inject(AuthService);

        const headers = req.headers.keys().reduce((o, key) => ({ ...o, [key]: req.headers.get(key) }), {});
        headers['Content-Type'] = 'application/json';
        const params = req.params.keys().reduce((o, key) => ({ ...o, [key]: req.params.get(key) }), {});

        const options = {
            data: req.body,
            headers,
            method: req.method,
            params,
            responseType: req.responseType,
            url: req.url,
        };

        return from(Http.request(options))
            .pipe(
                tap((capResponse: HttpResponse<any>) => {
                    const response: any = capResponse;
                    if (response?.data?.token) {
                        authService.accessToken = response.data.token;
                    }
                    if (response?.status === 401) {
                        authService.signOut();
                        // location.reload();
                        throw new Error('Unauthorized');
                    }
                }) as any,
                // catchError((error) => {
                //     // Catch "401 Unauthorized" responses
                //     // @capacitor-community/http doesn't return an HttpErrorResponse, so just look at the status
                //     if (error.status === 401) {
                //         // Sign out
                //         authService.signOut();

                //         // Reload the app
                //         // If an HttpClient request caused the 401, vs an AuthGuard, it got in an infinite loop...
                //         location.reload();
                //     }

                //     // Re-throw the error to propagate it downstream
                //     return throwError(() => error);
                // }),
                // tap((capResponse) => console.log('capResponse', capResponse)),
                map<any, HttpEvent<any>>(capResponse => {
                    const httpResponse = new HttpResponse({
                        status: capResponse.status,
                        headers: new HttpHeaders(capResponse.headers),
                        url: capResponse.url,
                        body: capResponse.data,
                    });
                    return httpResponse;
                }),
            );
    } else {
        return next(req);
    }
};
