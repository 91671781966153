import { Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppStateService } from './app-state.service';

import { StateListDto } from '../../../rest-api/src/general/dto/state-list.dto';
import { ZipcodeDto } from '../../../rest-api/src/general/dto/zipcode.dto';
import { TimezoneDto } from '../../../rest-api/src/general/dto/timezone.dto';

@Injectable({
    providedIn: 'root'
})
export class GeneralService {

    private isDevMode$ = this._appStateService.get$('apiURL').pipe(map(apiURL => apiURL.includes('dev-')));

    constructor(
        private _httpClient: HttpClient,
        private _appStateService: AppStateService,
    ) { }

    public copyDatabaseToDev(): Observable<any> {
        const apiURL = this._appStateService.get('apiURLNEW');
        return this._httpClient.get(`${apiURL}/general/copyDatabaseToDev`);
    }

    public lookupCity(city: string, state: string): Observable<ZipcodeDto[]> {
        return this._httpClient.get<ZipcodeDto[]>(`/general/city/${city}/${state}`);
    }

    public lookupZip(zipcode): Observable<ZipcodeDto> {
        return this._httpClient.get<ZipcodeDto>(`/general/zipcodes/${zipcode}`);
    }

    public stateList(): Observable<StateListDto> {
        return this._httpClient.get<StateListDto>('/general/states');
    }

    public timezones(): Observable<TimezoneDto[]> {
        return this._httpClient.get<TimezoneDto[]>('/general/timezones');
    }

}