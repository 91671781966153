import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, filter, Observable, of, switchMap, tap, throwError, timer } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

import { AppStateService } from '@nevtec/services/app-state.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { SettingsService } from '../../modules/admin/pages/settings/settings.service';
import { UserService } from 'app/core/user/user.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _router: Router,
        private _appStateService: AppStateService,
        private _settingsService: SettingsService,
        // private _translocoService: TranslocoService,
        private _userService: UserService,
    ) {
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            // Call the function to schedule token refresh
            this.scheduleTokenRefresh(this.accessToken);
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        if (token)
            this._appStateService.set('accessToken', token);
    }

    get accessToken(): string {
        return this._appStateService.get('accessToken');
    }

    set refreshToken(token: string) {
        if (token)
            this._appStateService.set('refreshToken', token);
    }

    get refreshToken(): string {
        return this._appStateService.get('refreshToken');
    }
    public setAPIURL(isDev: boolean) {
        const devPrefix = (isDev) ? 'dev-' : '';
        const apiURL = `https://${devPrefix}secure.petpassages.com/wp-json/app`;
        this._appStateService.set('apiURL', apiURL);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('/auth/reset-password', password);
    }

    // setVerbiage(language) {
    //     this._appStateService.set('verbiage', language);
    //     this._translocoService.setTranslation(language);
    // }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('/auth/sign-in', credentials).pipe(
            switchMap((response: any) => {

                this._appStateService.reset();

                // Store the access token in the local storage
                this.accessToken = response.accessToken;
                this.refreshToken = response.refreshToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // this._settingsService.account.get()
                //     .subscribe((response: AccountSettingsDto) => {
                //         this._appStateService.set('accountSettings', response.value);
                //     });

                this.scheduleTokenRefresh(response.accessToken);

                // Schedule token refresh based on the remaining time
                // this.scheduleTokenRefresh(expiresIn);

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post('/auth/sign-in-with-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response.accessToken) {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        this._appStateService.reset();

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        // return this.signInUsingToken();
        return of(this.accessToken !== undefined);
    }

    private scheduleTokenRefresh(token: string): void {
        // Get the remaining time until token expiration
        const remainingTime = AuthUtils.getRemainingExpirationTime(token);

        // Check if the remaining time is still valid
        if (remainingTime > 0) {
            // Calculate the delay before token refresh (e.g., 10 minutes before expiration)
            const refreshDelay = Math.max(0, remainingTime - 10 * 60);

            // Schedule token refresh
            timer(refreshDelay * 1000).pipe(
                switchMap(() => this.refresh())
            ).subscribe(
                () => console.log('Token refreshed successfully'),
                error => console.error('Token refresh failed:', error)
            );
        } else {
            console.log('Token has already expired. No refresh scheduled.');
        }
    }

    refresh(): Observable<any> {
        return this._httpClient.post('/auth/refresh', { refreshToken: this.refreshToken })
            .pipe(
                tap((response: any) => {
                    this.accessToken = response.accessToken;
                    this.refreshToken = response.refreshToken;
                }),
            );
    }

}
