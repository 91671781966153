import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { UserService } from 'app/core/user/user.service';

import { UserRole } from '../../../../rest-api/src/user/enum/user-role.enum';

@Component({
    selector: 'app-role-based-redirection',
    template: ''
})
export class RoleBasedRedirectionComponent implements OnInit {

    constructor(private router: Router, private userService: UserService) { }

    ngOnInit(): void {
        this.userService.role$
            .pipe(take(1))
            .subscribe((role) => {
                switch (role) {
                    case UserRole.PetParent:
                        this.router.navigate(['/pet-parent-gateway']);
                        break;
                    default:
                        this.router.navigate(['/passages']);
                        break;
                }
            });
    }
}
