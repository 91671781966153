import { inject } from '@angular/core';
import { CanActivateFn, CanActivateChildFn, Router } from '@angular/router';
import { of, switchMap, map, take } from 'rxjs';

import { UserRole } from '../../../../../rest-api/src/user/enum/user-role.enum';
import { UserService } from 'app/core/user/user.service';

export const RoleGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const userService = inject(UserService);

    const requiredRole: UserRole = route.data['requiredRole'];
    const notRole: UserRole = route.data['notRole'];

    return userService.role$.pipe(
        take(1),
        map((userRole: UserRole) => {
            if (requiredRole && userRole !== requiredRole) {
                router.navigate(['/signed-in-redirect']); // Trigger RoleBasedRedirectionComponent again
                return false;
            }

            if (notRole && userRole === notRole) {
                router.navigate(['/signed-in-redirect']); // Trigger RoleBasedRedirectionComponent again
                return false;
            }

            return true;
        })
    );
};
