import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BottomSheetService {
    private BehaviorSubjects = {
        _layers: new BehaviorSubject<Layers>(initialLayers),
        _options: new BehaviorSubject<any>({}),
    };
    public currentTemplate$ = new BehaviorSubject<TemplateRef<any> | string>('');
    public Observables = {
        layers$: this.BehaviorSubjects._layers.asObservable(),
        options$: this.BehaviorSubjects._options.asObservable(),
    };



    public close(): void {
        this.BehaviorSubjects._layers.next({
            ...this.getLayersCurrentValue(),
            detailsBottomSheet: false,
        });
    }



    public open(template, options = {}): void {
        this.currentTemplate$.next(template);
        this.BehaviorSubjects._layers.next({
            ...this.getLayersCurrentValue(),
            detailsBottomSheet: true,
        });
        this.BehaviorSubjects._options.next(options);
    }



    private getLayersCurrentValue(): Layers {
        return this.BehaviorSubjects._layers.getValue();
    }



}



export interface Layers {
    detailsBottomSheet: boolean;
}



export const initialLayers: Layers = {
    detailsBottomSheet: false,
};