import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'SafeHTML',
    standalone: true
})
export class SafeHTMLPipe implements PipeTransform {

    // Usage <div [innerHTML]="post.body | SafeHTML"></div>

    constructor(private DomSanitizer: DomSanitizer) {
    }

    transform(input: string): SafeHtml {
        return this.DomSanitizer.bypassSecurityTrustHtml(input);
    }

}
