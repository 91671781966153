import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);

    let apiURL = environment.apiUrl;

    if (req.url.indexOf('./') !== -1 || (req.url.indexOf('api/') !== -1))
        apiURL = ''; // If the request is for a local file, just leave it

    // Clone the request object
    // let newReq = req.clone();
    let newReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authService.accessToken}`), // It'll just be "Bearer" and no token if signing in
        url: apiURL + req.url,
    });

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    // if ( authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken) )
    // {
    //     newReq = req.clone({
    //         headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
    //     });
    // }

    // Response
    return next(newReq).pipe(
        catchError((error) => {
            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
                // Sign out
                authService.signOut();

                // Reload the app
                // If an HttpClient request caused the 401, vs an AuthGuard, it got in an infinite loop...
                // location.reload();
            }

            return throwError(error);
        }),
    );
};
