import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';

import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { RoleGuard } from './core/auth/guards/role.guard';

import { LayoutComponent } from 'app/layout/layout.component';
import { RoleBasedRedirectionComponent } from './core/auth/role-based-redirection.component';

import { UserRole } from '../../rest-api/src/user/enum/user-role.enum';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'passages' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    // { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'passages' },
    { path: 'signed-in-redirect', pathMatch: 'full', canActivate: [AuthGuard], component: RoleBasedRedirectionComponent },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') }
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'finalize', loadChildren: () => import('app/modules/landing/finalize/finalize.routes') },
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes') },
        ]
    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'pet-parent-gateway', loadChildren: () => import('app/modules/admin/pages/pet-parent-gateway/pet-parent-gateway.routes') },
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard, RoleGuard],
        canActivateChild: [AuthGuard, RoleGuard],
        data: { notRole: UserRole.PetParent },
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            // { path: 'dashboard', loadChildren: () => import('app/dashboard/dashboard.routes') },
            { path: 'dashboard', loadChildren: () => import('app/modules/admin/dashboards/finance/finance.routes') },
            { path: 'help-center', loadChildren: () => import('app/modules/admin/apps/help-center/help-center.routes') },
            { path: 'location', loadChildren: () => import('app/modules/admin/pages/location/location.routes') },
            { path: 'locations', loadChildren: () => import('app/modules/admin/pages/locations/locations.routes') },
            { path: 'passage', loadChildren: () => import('app/modules/admin/pages/passage/passage.routes') },
            { path: 'passages', loadChildren: () => import('app/modules/admin/pages/passages/passages.routes') },
            { path: 'price-schedule', loadChildren: () => import('app/modules/admin/pages/price-schedule/price-schedule.routes') },
            { path: 'price-schedules', loadChildren: () => import('app/modules/admin/pages/price-schedules/price-schedules.routes') },
            { path: 'settings', loadChildren: () => import('app/modules/admin/pages/settings/settings.routes') },
            { path: 'user', loadChildren: () => import('app/modules/admin/pages/contact/contact.routes') },
            { path: 'users', loadChildren: () => import('app/modules/admin/pages/contacts/contacts.routes') },
            {
                canActivate: [RoleGuard],
                canActivateChild: [RoleGuard],
                data: { requiredRole: UserRole.SuperAdmin },
                path: 'management', children: [
                    { path: 'cremations', loadChildren: () => import('app/modules/admin/apps/management-cremation/management.routes') },
                    { path: 'cremation-packages', loadChildren: () => import('app/modules/admin/apps/management-cremation-package/management.routes') },
                    { path: 'passage-status-type', loadChildren: () => import('app/modules/admin/apps/management-passage-status-type/management.routes') },
                    { path: 'products', loadChildren: () => import('app/modules/admin/apps/management-product/management.routes') },
                    { path: 'service-type', loadChildren: () => import('app/modules/admin/apps/management-service-type/management.routes') },
                    { path: 'services', loadChildren: () => import('app/modules/admin/apps/management-service/management.routes') },
                ]
            },

            // 404 & Catch all
            { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.routes') },
            { path: '**', redirectTo: '404-not-found' }
        ]
    },
];
