import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { SwalService } from '@nevtec/services/swal.service';
import { catchError, Observable, throwError } from 'rxjs';
import { map, filter } from 'rxjs/operators';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const errorInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const swalService = inject(SwalService);

    return next(req).pipe(
        filter((event: HttpEvent<unknown>): event is HttpResponse<unknown> => event instanceof HttpResponse),
        map((response: HttpResponse<unknown>) => {
            // Just return the response for local files
            if (req.url.indexOf('./') !== -1) {
                return response;
            }

            // const responseBody = (response.body as any);

            // if (!response.body || responseBody.Success === false) {
            //     swalService.toast('error', responseBody.Error, 'Error');
            //     throw new HttpErrorResponse({ error: responseBody.Error });
            // }

            return response;
        }),
        catchError((err: HttpErrorResponse) => {
            // Handle non-auth errors (400, 500, etc.)
            // 401 will happen when the token is no longer good, 403 will happen if it's an invalid login
            if (![401, 403].includes(err.status)) {
                const errorMessage = getFriendlyErrorMessage(err);
                swalService.toast('error', errorMessage, 'Error');
            }

            // Handle auth errors (401, 403)
            if ([401, 403].includes(err.status)) {
                authService.signOut();
                swalService.toast('error', 'You have been logged out. Please log in again.', 'Error');
            }

            // Extract and re-throw the error message
            const error = err.error?.message || err.statusText || 'An unknown error occurred';
            return throwError(() => new Error(error));
        }),
    );
};

/**
 * Get a friendly error message based on the server response
 *
 * @param error
 * @returns {string}
 */
function getFriendlyErrorMessage(error: HttpErrorResponse): string {
    let message = 'An error occurred';

    // Handle different status codes or error messages
    if (error.status === 400) {
        message = error.error.message || 'Bad Request';
    } else if (error.status === 500) {
        message = 'Internal Server Error. Please try again later.';
    } else if (error.error && error.error.message) {
        message = error.error.message;
    } else {
        message = error.statusText || message;
    }

    return message;
}