import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MobileAppService {

    public isIOS$ = this.checkPlatform().pipe(map(platform => platform === 'ios'));
    public isMobile$ = this.checkPlatform().pipe(map(platform => platform !== 'web'));

    private checkPlatform(): Observable<string> {
        return of(Capacitor.getPlatform());
    }

    constructor() { }
}