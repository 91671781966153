import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
    name: 'SafeURL'
})
export class SafeURLPipe implements PipeTransform {

    // Usage <div [innerHTML]="post.body | SafeHTML"></div>

    constructor(private DomSanitizer: DomSanitizer) {
    }

    transform(input: string): SafeUrl {
        return this.DomSanitizer.bypassSecurityTrustResourceUrl(input);
    }

}
