import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap, take, map, pluck, switchMap, iif, of } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Title } from '@angular/platform-browser';

import { AppStateService } from '@nevtec/services/app-state.service';
import { UserService } from 'app/core/user/user.service';

import { FuseNavigationItem } from '@fuse/components/navigation';
import { UserRole } from '../../../../rest-api/src/user/enum/user-role.enum';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _title: Title,
        private _appStateService: AppStateService,
        private _translocoService: TranslocoService,
        private _userService: UserService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            map((navigation) => {
                return {
                    compact: this.adjustNavigationType(navigation.default, 'aside'),
                    default: navigation.default,
                    futuristic: this.adjustNavigationType(navigation.default, 'collapsable'),
                    horizontal: this.adjustNavigationType(navigation.default, 'collapsable')
                };
            }),
            switchMap((navigation) =>
                this._userService.role$.pipe(
                    take(1),
                    map((role) => this.filterNavigationByRole(navigation, role))
                )
            ),
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }

    /**
     * Adjust navigation type for items with children
     */
    private adjustNavigationType(navigation: FuseNavigationItem[], type: 'aside' | 'collapsable' | 'basic' | 'divider' | 'group' | 'spacer'): FuseNavigationItem[] {
        return navigation.map(item => {
            if (item.children && item.children.length > 0) {
                return {
                    ...item,
                    type: type,
                    children: this.adjustNavigationType(item.children, type)
                };
            }
            return item;
        });
    }

    /**
     * Filter navigation based on user role
     */
    private filterNavigationByRole(navigation: Navigation, role: UserRole): Navigation {
        const filteredNavigation: Navigation = {
            compact: [],
            default: [],
            futuristic: [],
            horizontal: [],
        };

        Object.keys(navigation).forEach((key) => {
            filteredNavigation[key] = navigation[key].filter((item) => this.isRoleAllowedItem(item, role));
        });

        return filteredNavigation;
    }

    /**
     * Check if a navigation item is allowed for a role
     */
    private isRoleAllowedItem(item: FuseNavigationItem, role: UserRole): boolean {
        // IDs are set with the lowest level possible to allow for easier filtering
        // EX) vet.dashboard means vet, franchisestaff, franchise, superadmin can see it
        const roleAllowances = {
            [UserRole.SuperAdmin]: ['admin.', 'franchise.', 'franchisestaff.', 'user.', 'vet.'],
            [UserRole.Franchise]: ['franchise.', 'franchisestaff.', 'user.', 'vet.'],
            [UserRole.FranchiseStaff]: ['franchisestaff.', 'user.', 'vet.'],
            [UserRole.Vet]: ['user.', 'vet.'],
            [UserRole.PetParent]: ['pet-parent-gateway']
        };

        const allowedIds = roleAllowances[role] || [];

        if (allowedIds.some(allowedId => item.id && item.id.startsWith(allowedId))) {
            return true;
        }
        if (item.children) {
            item.children = item.children.filter(child => this.isRoleAllowedItem(child, role));
        }
        return false;
    }

    setTitle(title: string, transloco: boolean) {
        this._title.setTitle(title);
        // this._userService.user$
        //     .pipe(
        //         take(1),
        //         pluck('company'),
        //         switchMap((company_name: string) => {
        //             return iif(() =>
        //                 (transloco)
        //                 , this._translocoService.selectTranslate(title)
        //                     .pipe(
        //                         map((translatedTitle: string) => (`${translatedTitle} - ${company_name}`)),
        //                     )
        //                 , of(`${title} - ${company_name}`)
        //             )
        //         })
        //     )
        //     .subscribe((pageTitle: string) => {
        // this._title.setTitle(pageTitle);
        // });
    }
}
