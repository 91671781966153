// https://blog.angularindepth.com/total-guide-to-dynamic-angular-animations-that-can-be-toggled-at-runtime-be5bb6778a0a

import { trigger, animate, transition, style, query, group, state, animateChild, stagger, sequence } from '@angular/animations';

export const ROUTE_ANIMATIONS_ELEMENTS = 'route-animations-elements';

const STEPS_ALL: any[] = [
    query(':enter > *', style({ opacity: 0, position: 'fixed' }), {
        optional: true
    }),
    query(':enter .' + ROUTE_ANIMATIONS_ELEMENTS, style({ opacity: 0 }), {
        optional: true
    }),
    sequence([
        query(
            ':leave > *',
            [
                style({ transform: 'translateY(0%)', opacity: 1 }),
                animate(
                    '0.25s ease-in-out',
                    style({ transform: 'translateY(0%)', opacity: 0 })
                ),
                style({ position: 'fixed' })
            ],
            { optional: true }
        ),
        query(
            ':enter > *',
            [
                style({
                    transform: 'translateY(0%)',
                    opacity: 0,
                    position: 'static'
                }),
                animate(
                    '0.8s ease-in-out',
                    style({ transform: 'translateY(0%)', opacity: 1 })
                )
            ],
            { optional: true }
        )
    ]),
    query(
        ':enter .' + ROUTE_ANIMATIONS_ELEMENTS,
        stagger(100, [
            style({ transform: 'translateY(0%)', opacity: 0 }),
            animate(
                '0.8s ease-in-out',
                style({ transform: 'translateY(0%)', opacity: 1 })
            )
        ]),
        { optional: true }
    )
];
const STEPS_NONE = [];
const STEPS_PAGE = [STEPS_ALL[0], STEPS_ALL[2]];
const STEPS_ELEMENTS = [STEPS_ALL[1], STEPS_ALL[3]];

export const FadeAnimation2 = trigger('FadeAnimation2', [
    transition('* => *', STEPS_ALL),
    transition('* => *', STEPS_NONE),
    transition('* => *', STEPS_PAGE),
    transition('* => *', STEPS_ELEMENTS)
]);
