import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'titleCaseSpace',
    standalone: true
})
export class TitleCaseSpacePipe implements PipeTransform {

    transform(value: any, ...args: any[]): unknown {
        if (!value) return value;

        // Replace hyphens with spaces
        value = value.replace(/[-_]/g, ' ');

        // Insert spaces before uppercase letters
        value = value.replace(/([A-Z])/g, ' $1');

        // Split the string into words, capitalize each word, and join them back together
        value = value.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');

        return value.trim();
    }

}
