import { Observable, tap, pluck, map, switchMap, shareReplay, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppStateService } from '@nevtec/services/app-state.service';
import { UserService } from 'app/core/user/user.service';

// import { AccountSettingsDto } from './../../../../../../rest-api/src/account/dto/account-settings.dto';
import { CompanyDto } from '../../../../../../rest-api/src/company/dto/company.dto';
import { CompanySettingsDto } from '../../../../../../rest-api/src/company/dto/company-settings.dto';
import { UserDto } from '../../../../../../rest-api/src/user/dto/user.dto';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {



    constructor(
        private _httpClient: HttpClient,
        private _appStateService: AppStateService,
        private _userService: UserService,
    ) { }



    // public account = {
    //     get: (path: string = ''): Observable<any> => {
    //         return this._httpClient.get<AccountSettingsDto>(`/account/settings`);
    //     },
    //     save: (data): Observable<any> => {
    //         return this._httpClient.patch<AccountSettingsDto>('/account/setting', data)
    //             .pipe(
    //                 map((response: any) => response.settings),
    //                 tap((account_settings: AccountSettingsDto) => {
    //                     const userState: UserDto = this._appStateService.get('user');
    //                     userState.account.settings = account_settings;
    //                     this._appStateService.set('user', userState);
    //                 })
    //             );
    //     },
    // }



    // public company = {
    //     get: (path?: string): Observable<any> => {
    //         return this._httpClient.get(`/company-settings/${path}`);
    //     },
    //     save: (type: string, data: any): Observable<any> => {
    //         return this._httpClient.patch(`/company-settings`, { [type]: data });
    //         // return this._userService.location_id$
    //         //     .pipe(
    //         //         switchMap((location_id: string) => this._httpClient.patch(`/company-settings/${location_id}`, { [type]: data })),
    //         //     );
    //     },
    // }

    public company = {
        get: (): Observable<CompanySettingsDto> => {
            return this._appStateService.get$('user').pipe(
                take(1),
                pluck('company_id'),
                switchMap((company_id: string) => {
                    return this._httpClient.get<CompanyDto>(`/company/${company_id}`).pipe(
                        tap((company: CompanyDto) => {
                            this._appStateService.set('companySettings', company.settings);
                        }),
                        map((company: CompanyDto) => company.settings as CompanySettingsDto),
                        shareReplay(1),
                    );
                }),
            );
        },
        save: (data: Partial<CompanySettingsDto>): Observable<CompanySettingsDto> => {
            return this._appStateService.get$('user').pipe(
                take(1),
                pluck('company_id'),
                switchMap((company_id: string) => {
                    return this._httpClient.patch<CompanySettingsDto>(`/company/${company_id}/settings`, data).pipe(
                        tap((companySettings: CompanySettingsDto) => {
                            this._appStateService.set('companySettings', companySettings);
                        }),
                        shareReplay(1),
                    );
                })
            );
        }
    };



    public user = {
        // get: (location_id: string): Observable<any> => {
        //     return this._httpClient.get<any>(`/account-settings/${location_id}`);
        // },
        save: (type: string, data: any): Observable<any> => {
            return this._userService.updateSetting({ [type]: data });
        },
    }



}
