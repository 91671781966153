import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, RouterEvent, NavigationEnd, Event } from '@angular/router';
import { debounceTime, delay, distinctUntilChanged, filter, map, skip, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { Observable, of, pipe, timer } from 'rxjs';

import { CapacitorUpdater } from '@capgo/capacitor-updater';
CapacitorUpdater.notifyAppReady(); // Keep this here even though on the web you'll see "Cannot notify App Ready in web" in the console...otherwise the app will revert

import { AppStateService } from '@nevtec/services/app-state.service';
import { MobileAppService } from '@nevtec/services/mobile-app.service';
import { SwalService } from '@nevtec/services/swal.service';

import { CheckVersionDto } from '../../../../rest-api/src/general/dto/check-version.dto';
@Injectable({
    providedIn: 'root',
})
export class CheckVersionService {
    public VERSION = '2.8.0';
    private RefreshLimit = 1;
    private RetryThreshold = (2 * 60) * 1000;
    private StateKey = 'VersionRefreshCount';

    private isMobile$ = this._mobileAppService.isMobile$;

    constructor(
        private _httpClient: HttpClient,
        private _router: Router,
        private _appStateService: AppStateService,
        private _mobileAppService: MobileAppService,
        private _swalService: SwalService,
    ) { }

    // I'm calling this._checkVersionService.checkAndUpdate(); explicitly in sign-in.component.ts
    public async checkAndUpdate() {
        this.isMobile$
            .pipe(
                take(1),
                switchMap(async (isMobile: boolean) => {
                    if (isMobile) {
                        try {
                            const currentVersion = await CapacitorUpdater.current();
                            const response = await this._httpClient.get<any>(`/general/checkVersion?v=${this.VERSION}&app=true`).toPromise();

                            const url = response.url;
                            const shouldUpdate = (url !== undefined);

                            if (shouldUpdate) {
                                this._swalService.toast('success', `Downloading new update ${response.version}`, 'Download started');
                                const newVersion = await CapacitorUpdater.download({ url, version: response.version });
                                await CapacitorUpdater.set(newVersion);

                                location.reload();
                            }
                        } catch (error) {
                            console.error('Error checking or applying update:', error);
                        }
                    }
                }),
            )
            .subscribe();
    }

    public initialize(): void {
        /* ======================================================
        For mobile apps, we explicitly call checkAndUpdate() in
        the sign-in.component.ts file
        
        For desktop, we first check if it's mobile, and if not,
        with each route change, if the version does not match
        the server version, we'll try to refresh up to this.RefreshLimit.
        When the route changes, it'll try again.
        ====================================================== */
        // let isFirstRouteChange = this._appStateService.get('isFirstRouteChange') || true;

        this.isMobile$
            .pipe(take(1))
            .subscribe((isMobile: boolean) => {

                if (!isMobile) {
                    this._router.events
                        .pipe(
                            map((e: Event): RouterEvent | null => e instanceof NavigationEnd ? e : null),
                            map((e: RouterEvent | null) => e?.url),
                            filter(url => !!url && !['/locked', '/sign-out', '/sign-in'].some(endpoint => url.startsWith(endpoint))), // sign-in because I call this._appStateService.reset() which removes this.StateKey and it will be an infinite loop
                            distinctUntilChanged(),
                            tap(() => this._appStateService.set('isFirstRouteChange', false)),
                            switchMap((url: string) => this._httpClient.get(`/general/checkVersion?v=${this.VERSION}`)),
                            filter((response: CheckVersionDto) => response.refresh),
                            switchMap((response: CheckVersionDto) =>
                                this._appStateService.get$(this.StateKey).pipe(
                                    take(1),
                                    map((refreshCount: number) => refreshCount || 0),
                                    tap((refreshCount: number) => this._appStateService.set(this.StateKey, refreshCount + 1)),
                                    tap((refreshCount: number) => {
                                        if (refreshCount >= this.RefreshLimit) {
                                            this._appStateService.set(this.StateKey, 0);
                                        }
                                    }),
                                )
                            ),
                            tap((refresh) => console.log(refresh)),
                            filter((refreshCount: number) => refreshCount < this.RefreshLimit),
                            tap((refresh) => console.log(refresh)),
                            tap(() => location.reload()),
                        )
                        .subscribe();
                }

            });

    }

}