// import { HttpParams } from '@angular/common/http';

// export class HttpParamsHelper {
//     /* ======================================================
//     populate is an array, because we want it to show up multiple times
//     in the query string
//     EX) { populate: ['last_updated_by', 'locked_by;first_name,full_name,last_name'] }
//     Generates:
//     populate: last_updated_by
//     populate: locked_by;first_name,full_name,last_name

//     select is a string (or you can do a single-place array of a string)
//     EX) { select: 'first_name, full_name, last_name' }
//     NOTE: You can use spaces for readability
//     Generates:
//     select: first_name, full_name, last_name
//     ====================================================== */

//     static setHttpParams(data?): HttpParams {
//         let params = new HttpParams();
//         let sortParams: string[] = [];

//         if (data) {
//             Object.entries(data).forEach(([key, value]) => {
//                 if (value !== undefined && value !== null) {
//                     if (Array.isArray(value)) {
//                         if (key === 'or' || key === 'and') {
//                             // Handle OR and AND operators
//                             value.forEach((condition, index) => {
//                                 Object.entries(condition).forEach(([field, fieldValue]) => {
//                                     if (typeof fieldValue === 'object') {
//                                         // Convert object to JSON string
//                                         params = params.append(`${key}[${index}][${field}]`, JSON.stringify(fieldValue));
//                                     } else {
//                                         // Otherwise, append the fieldValue as is
//                                         params = params.append(`${key}[${index}][${field}]`, String(fieldValue));
//                                     }
//                                 });
//                             });
//                         } else {
//                             // Handle arrays for the same key
//                             value.forEach(item => params = params.append(key, item));
//                         }
//                     } else if (typeof value === 'object') {
//                         // Handle nested objects
//                         const nestedCondition = Object.entries(value).length > 0;
//                         if (nestedCondition) {
//                             const [matchingNestedKey, nestedValue] = Object.entries(value)[0] || [];
//                             if (matchingNestedKey) {
//                                 // Check if matchingNestedKey is in the specified conditions
//                                 // USAGE - this._contactService.getAll({ location_id: this.location_id, role: { in: [UserRole.Franchise, UserRole.FranchiseStaff] } })
//                                 const isComparisonOperator = ['eq', 'gt', 'gte', 'lt', 'lte', 'ne', 'in', 'nin'];
//                                 if (isComparisonOperator.includes(matchingNestedKey)) {
//                                     if (['in', 'nin'].includes(matchingNestedKey) && Array.isArray(nestedValue)) {
//                                         // Handle in and nin operators
//                                         params = params.append(key, `${matchingNestedKey}:${nestedValue.join(',')}`);
//                                     } else {
//                                         const nestedValueAsString = typeof nestedValue === 'object' ? JSON.stringify(nestedValue) : nestedValue;
//                                         params = params.set(key, `${matchingNestedKey}:${nestedValueAsString}`);
//                                     }
//                                 } else {
//                                     // Assume it's a sort
//                                     Object.entries(value).forEach(([sortKey, sortValue]) => {
//                                         const sortParam = sortValue === -1 ? `-${sortKey}` : sortKey;
//                                         sortParams.push(sortParam);
//                                     });
//                                 }
//                             }
//                         }
//                     } else {
//                         // Handle other types of values
//                         params = params.set(key, String(value));
//                     }
//                 }
//             });
//         }

//         // Append sort parameters if any
//         if (sortParams.length > 0) {
//             params = params.append('sort', sortParams.join(','));
//         }

//         return params;
//     }
// }


import { HttpParams } from '@angular/common/http';

export class HttpParamsHelper {
    /* ======================================================
    The `setHttpParams` method is designed to convert an object
    into an instance of Angular's `HttpParams`, which can then be
    used to construct a URL query string.

    - `populate`: an array of strings, where each string represents
      a field to populate in the query. This will generate multiple
      query parameters with the same key.
      EX) { populate: ['last_updated_by', 'locked_by;first_name,full_name,last_name'] }
      Generates:
      populate: last_updated_by
      populate: locked_by;first_name,full_name,last_name

    - `select`: a string or a single-element array that lists fields
      to be selected, separated by commas. This will generate a single
      query parameter.
      EX) { select: 'first_name, full_name, last_name' }
      NOTE: Spaces can be used for readability but will be encoded.
      Generates:
      select: first_name,full_name,last_name

    - `sort`: an array of sorting instructions. Prefix a field with a
      minus sign (-) to indicate descending order.
      EX) { sort: '-date_entered, name' }
      Generates:
      sort: -date_entered,name
    ====================================================== */

    static setHttpParams(data?): HttpParams {
        let params = new HttpParams();
        let sortParams: string[] = [];

        if (data) {
            Object.entries(data).forEach(([key, value]) => {
                if (value !== undefined && value !== null) {
                    if (Array.isArray(value)) {
                        // Handle arrays, which could be for `populate`, `or`, `and`, etc.
                        value.forEach(item => {
                            if (typeof item === 'object') {
                                // For complex objects within arrays (e.g., `or`, `and` conditions)
                                Object.entries(item).forEach(([subKey, subValue]) => {
                                    params = params.append(`${key}[${subKey}]`, this.formatValue(subValue));
                                });
                            } else {
                                // Simple array items (e.g., `populate`)
                                params = params.append(key, item);
                            }
                        });
                    } else if (typeof value === 'object') {
                        // Handle nested objects, such as for comparison operators or sorting
                        Object.entries(value).forEach(([subKey, subValue]) => {
                            const isComparisonOperator = ['eq', 'gt', 'gte', 'lt', 'lte', 'ne', 'in', 'nin'].includes(subKey);
                            if (isComparisonOperator) {
                                // Handle comparison operators (e.g., `in`, `nin`, `gt`, `lt`)
                                params = this.handleComparisonOperators(params, key, subKey, subValue);
                            } else {
                                // If not a comparison operator, assume it's for sorting
                                const sortParam = subValue === -1 ? `-${subKey}` : subKey;
                                sortParams.push(sortParam);
                            }
                        });
                    } else {
                        // Handle simple key-value pairs (e.g., `select`, `limit`, `page`)
                        params = params.set(key, String(value));
                    }
                }
            });
        }

        // Append any sort parameters as a single `sort` query parameter
        if (sortParams.length > 0) {
            params = params.append('sort', sortParams.join(','));
        }

        return params;
    }

    /**
     * Helper method to format values into a string format.
     * - If the value is an object, it converts it into a JSON string.
     * - Otherwise, it converts the value to a string.
     */
    private static formatValue(value: any): string {
        return typeof value === 'object' ? JSON.stringify(value) : String(value);
    }

    /**
     * Handles comparison operators such as `eq`, `gt`, `lt`, `in`, `nin`.
     * - `in` and `nin` operators handle arrays by joining the values with commas.
     * - Other operators append the key with the operator and the formatted value.
     */
    private static handleComparisonOperators(params: HttpParams, key: string, operator: string, value: any): HttpParams {
        if (['in', 'nin'].includes(operator) && Array.isArray(value)) {
            // Handle `in` and `nin` operators where the value is an array
            return params.append(key, `${operator}:${value.join(',')}`);
        } else {
            // Handle other comparison operators
            return params.append(key, `${operator}:${this.formatValue(value)}`);
        }
    }
}
