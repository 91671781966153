import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// import { CRUDBaseService } from '@nevtec/services/crud-base.service';

// import { BaseArrayResponseDto } from '@nevtec/interfaces/base-array-response.dto';
import { BaseResponse } from 'app/interfaces/base-response.interface';
import { Passage } from '../interfaces/passage.interface';
import { PassagePrintUpload } from '../interfaces/passage-paw-print.interface';

export interface BulkAddSignatureData {
    passages: Passage[];
    signature: string;
    status: string;
}

export interface PassagesResponse extends BaseResponse {
    Duration: number;
    Passages: Passage[];
}

export interface PassagesSearchParams {
    location_id?: string;
    search?: string;
    show?: number;
    status?: string;
}
export interface PrintUpload {
    passage: Passage;
    print_upload: string;
    type: string;
}

@Injectable({
    providedIn: 'root'
})
// export class PassageService extends CRUDBaseService<Dto> {



//     constructor() {
//         super('passage');
//     }



//     // public getAll(data?: any): Observable<BaseArrayResponseDto<Dto>> {
//     //     return super.getAll({ ...data, ...{ populate: ['locked_by;first_name,full_name,last_name'], select: ['date_entered, name, status'] } }); // We don't need to populate status, it will match based on ObjectId as well...and when we change it, the account-status-type will send an object to the back end and update the "status_title" property properly
//     // }



// }

export class PassageService {

    private BehaviorSubjects = {
        _lastPassage: new BehaviorSubject<number>(0),
    };

    public Observables = {
        hasLastPassage$: this.BehaviorSubjects._lastPassage.asObservable()
            .pipe(
                map(order_id => order_id !== 0),
            ),
        lastPassage$: this.BehaviorSubjects._lastPassage.asObservable(),
    };



    constructor(
        private HttpClient: HttpClient,
    ) { }



    public addServices(data): Observable<any> {
        return this.HttpClient.post('/addServices', data);
    }



    public bulkAddSignature(data: BulkAddSignatureData): Observable<BaseResponse> {
        return this.HttpClient.post<BaseResponse>('/bulkAddSignature', data);
    }



    public checkAuthForm(data): Observable<any> {
        return this.HttpClient.post('/checkAuthForm', data);
    }



    public generateAuthForm(data): Observable<any> {
        return this.HttpClient.post('/generateAuthForm', data);
    }



    public generateSecureID(data): Observable<any> {
        return this.HttpClient.post('/generateSecureID', data);
    }



    public getPassage(data): Observable<Passage> {
        return this.HttpClient.post<any[]>('/details', data)
            .pipe(
                map((response: any) => response.Passage),
                tap((response: Passage) => this.setLastPassageID(response.order_id)),
                map((response: Passage) => {
                    if (response.pet_parent && typeof response.pet_parent === 'string')
                        response.pet_parent = JSON.parse(response.pet_parent) as { first_name: string; last_name: string; };
                    return response;
                }),
                map((response: Passage) => {
                    if (response.cremation_options_cremation_video_true_false)
                        response.cremation_options_cremation_video_true_false = (response.cremation_options_cremation_video_true_false === '1');
                    if (response.cremation_options_goodbye_true_false)
                        response.cremation_options_goodbye_true_false = (response.cremation_options_goodbye_true_false === '1');
                    if (response.cremation_options_witness_cremation_true_false)
                        response.cremation_options_witness_cremation_true_false = (response.cremation_options_witness_cremation_true_false === '1');

                    return response;
                }),
                map((response: Passage) => {
                    if (response.custody && typeof response.custody === 'string')
                        response.custody = JSON.parse(response.custody) as any;
                    return response;
                }),
                map((response: Passage) => {
                    if (response.order_items) {
                        response.order_items = JSON.parse(response.order_items as string);
                        if (Array.isArray(response.order_items)) {
                            response.order_items.forEach(oi => {
                                if (oi.details) {
                                    oi.details = JSON.parse(oi.details);
                                    oi.details = oi.details.reduce((accumulator, details) => {
                                        accumulator[details.meta_key] = details.meta_value;
                                        return accumulator;
                                    });
                                }
                            });
                        }
                    }
                    return response;
                }),
            );
    }



    public getAll(data?: PassagesSearchParams): Observable<Passage[]> {
        return this.HttpClient.post<PassagesResponse>('/list', data)
            .pipe(
                map((response: PassagesResponse) => response.Passages),
                map((response: Passage[]) => {
                    const acfDateRegex = /(\d{4})(\d{2})(\d{2})/;
                    response.forEach((r: Passage) => {
                        if (r.clay_paw_print_completed_date)
                            r.clay_paw_print_completed_date = r.clay_paw_print_completed_date.replace(acfDateRegex, '$2/$3/$1');
                        if (r.fur_clipping_completed_date)
                            r.fur_clipping_completed_date = r.fur_clipping_completed_date.replace(acfDateRegex, '$2/$3/$1');
                        if (r.pet_parent && typeof r.pet_parent === 'string')
                            r.pet_parent = JSON.parse(r.pet_parent) as { first_name: string; last_name: string; };
                        if (r.order_items)
                            r.order_items = (r.order_items as string).split('|');
                        if (r.print_uploads) {
                            const parsedPrintUploads: PassagePrintUpload[] = JSON.parse(r.print_uploads as string);
                            const typeCounts: Map<string, number> = new Map();

                            for (const upload of parsedPrintUploads) {
                                const type = upload.type;

                                if (typeCounts.has(type)) {
                                    typeCounts.set(type, typeCounts.get(type) + 1);
                                } else {
                                    typeCounts.set(type, 1);
                                }
                            }

                            const resultPrintUploads: PassagePrintUpload[] = Array.from(typeCounts.keys()).map(type => ({
                                count: typeCounts.get(type),
                                type: type
                            }));

                            r.print_uploads = resultPrintUploads;
                        }
                    });

                    return response;
                }),
            );
    }



    public getFinalizeClasses(p: Passage): string {
        let classStr = '';

        if (p.commission_total > 0 && (p.franchise_id !== p.location_id)) {
            classStr = 'text-orange-500';
        } else if (p.in_house === '1') {
            classStr = 'text-fuchsia-500';
        } else {
            classStr = 'text-yellow-300';
        }

        return `icon-size-4 ${classStr}`;
    }



    public getFinalizeType(p: Passage): string {
        if (p.commission_total > 0 && (p.franchise_id !== p.location_id)) {
            return 'Vet Finalize';
        } else if (p.in_house === '1') {
            return 'In-House Finalize';
        } else {
            return 'Franchise Site Finalize';
        }
    }



    public getServices(data): Observable<any> {
        return this.HttpClient.post('/getServices', data);
    }



    public getStatusColor(status: string): string {
        const colors = {
            all: '#d3b58d',
            trash: '#b20600',
            'wc-pre-need': '#737578',
            'wc-retail-only-order': '#a0522d',
            'wc-passage-created': '#e3e2e2',
            'wc-pet-ready-for-pic': '#f9dd29',
            'wc-picked-up': '#f59f1d',
            'wc-pet-received': '#fb83ed',
            'wc-pet-ready-for-cre': '#b7f749',
            'wc-cremation-in-prog': '#010918',
            'wc-cremation-complet': '#17b7ec',
            'wc-pet-ready-for-ret': '#068eef',
            'wc-waiting-for-payme': '#0066bf',
            'wc-waiting-for-pick': '#6553f7',
            'wc-passage-on-hold': '#f21115',
            'wc-pet-returned-home': '#9e47ea',
            'wc-communal-crematio': '#c1bafc',
            'wc-communal-scattere': '#c1bafc',
            'wc-retail-only-ord-2': '#800000',
        };
        return colors[status];
    }



    public getStatusName(status: string): string {
        const statuses = {
            trash: 'Trash',
            'wc-pre-need': 'Pre-Need',
            'wc-retail-only-order': 'Retail Only - Order Received',
            'wc-passage-created': 'Passage Created',
            'wc-pet-ready-for-pic': 'Pet Ready for Pick Up',
            'wc-picked-up': 'Pet Picked Up',
            'wc-pet-received': 'Pet Received at Pet Passages',
            'wc-pet-ready-for-cre': 'Pet Ready for Cremation',
            'wc-cremation-in-prog': 'Cremation in Progress',
            'wc-cremation-complet': 'Cremation Complete',
            'wc-pet-ready-for-ret': 'Pet Ready for Return',
            'wc-waiting-for-payme': 'Waiting for Payment',
            'wc-waiting-for-pick': 'Waiting for Pick Up - Paid',
            'wc-passage-on-hold': 'Passage On Hold',
            'wc-pet-returned-home': 'Pet Returned Home',
            'wc-communal-crematio': 'Communal Cremation Complete',
            'wc-communal-scattere': 'Communal Complete - Scattered',
            'wc-retail-only-ord-2': 'Retail Only - Order Completed',
        };
        return statuses[status];
    }



    public sendEmail(data): Observable<any> {
        return this.HttpClient.post('/sendEmail', data);
    }



    public setLastPassageID(order_id: number) {
        this.BehaviorSubjects._lastPassage.next(order_id);
    }



    public updatePassage(passage: Passage): Observable<any> {
        return this.HttpClient.post('/updatePassage', { passage });
    }



    public uploadPrint(data: PrintUpload): Observable<any> {
        return this.HttpClient.post('/uploadPrint', data);
    }



}