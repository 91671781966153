/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'vet.dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'mat_solid:dashboard',
        link: '/dashboard'
    },
    {
        id: 'vet.passages',
        title: 'Passages',
        type: 'basic',
        icon: 'mat_solid:pets',
        link: '/passages'
    },
    {
        id: 'vet.users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_solid:users',
        link: '/users'
    },
    {
        id: 'vet.locations',
        title: 'Locations',
        type: 'basic',
        icon: 'mat_solid:location_on',
        link: '/locations'
    },
    {
        id: 'pet-parent-gateway',
        title: 'Pet Parent Gateway',
        type: 'basic',
        icon: 'mat_solid:pets',
        link: '/pet-parent-gateway'
    },
    {
        id: 'franchise.price-schedules',
        title: 'Price Schedules',
        type: 'basic',
        icon: 'mat_solid:account_tree',
        link: '/price-schedules'
    },
    {
        id: 'user.settings',
        title: 'Settings',
        type: 'basic',
        icon: 'mat_solid:settings',
        link: '/settings'
    },
    {
        id: 'user.help',
        title: 'Help Center',
        type: 'basic',
        icon: 'mat_solid:help_center',
        link: '/help-center'
    },
    {
        id: 'admin.management',
        title: 'Management',
        type: 'collapsable',
        icon: 'mat_solid:badge',
        children: [
            {
                id: 'admin.management.cremation',
                title: 'Cremations',
                type: 'basic',
                icon: 'mat_solid:add_chart',
                link: '/management/cremations'
            },
            {
                id: 'admin.management.cremation-packages',
                title: 'Cremation Packages',
                type: 'basic',
                icon: 'mat_solid:add_chart',
                link: '/management/cremation-packages'
            },
            {
                id: 'admin.management.passage-status-type',
                title: 'Passage Status Type',
                type: 'basic',
                icon: 'mat_solid:category',
                link: '/management/passage-status-type'
            },
            {
                id: 'admin.management.products',
                title: 'Products',
                type: 'basic',
                icon: 'mat_solid:add_chart',
                link: '/management/products'
            },
            {
                id: 'admin.management.services',
                title: 'Services',
                type: 'basic',
                icon: 'mat_solid:add_chart',
                link: '/management/services'
            },
            // {
            //     id: 'admin.management.service-type',
            //     title: 'Service Categories',
            //     type: 'basic',
            //     icon: 'mat_solid:category',
            //     link: '/management/service-type'
            // },
        ],
    },
];
export const compactNavigation: FuseNavigationItem[] = [];
export const futuristicNavigation: FuseNavigationItem[] = [];
export const horizontalNavigation: FuseNavigationItem[] = [];
