import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'AbsoluteValue'
})
export class AbsoluteValuePipe implements PipeTransform {

    constructor() { }

    transform(value, args: string[]): any {
        return Math.abs(value);
    }

}
