import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';

import { GeneralService } from '@nevtec/services/general.service';
import { HttpCacheService } from '../type-shared/http-cache.service';
import { LocationService } from 'app/modules/admin/pages/location/location.service';
import { UserService } from 'app/core/user/user.service';

import { LocationDto } from '../../../../../rest-api/src/location/dto/location.dto';
import { TimezoneDto as Dto } from '../../../../../rest-api/src/general/dto/timezone.dto';

@Injectable({
    providedIn: 'root'
})
export class TimezoneService extends HttpCacheService {

    private cacheKey = 'timezones';
    public userTimezone$: Observable<string> = this._userService.location_id$
        .pipe(
            switchMap((location_id: string) => this.getLocationTimezone(location_id)),
        );

    constructor(
        private _generalService: GeneralService,
        private _locationService: LocationService,
        private _userService: UserService,
    ) {
        super();
    }

    private fetch(): Observable<Dto[]> {
        return this._generalService.timezones()
            .pipe(
                this.handleLocalCache(this.cacheKey),
            );
    }

    public get(): Observable<Dto[]> {
        return this.getFromCacheOrFetch(this.cacheKey, () => this.fetch());
    }

    public getLocationTimezone(locationId: string): Observable<string> {
        const locationCacheKey = `location-timezone-${locationId}`;

        return this.getFromCacheOrFetch(locationCacheKey, () =>
            this._locationService.get(locationId, { select: 'timezone' })
                .pipe(
                    map((location: LocationDto) => location.timezone),
                )
        );
    }

}