import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';

import { CRUDBaseService } from '@nevtec/services/crud-base.service';

import { BaseArrayResponseDto } from '../../../../../../rest-api/src/shared/base/base-array-response.dto';
import { LocationDto as Dto } from './../../../../../../rest-api/src/location/dto/location.dto';

@Injectable({
    providedIn: 'root'
})
export class LocationService extends CRUDBaseService<Dto> {

    constructor(
        private _formBuilder: FormBuilder,
    ) {
        super('location');
    }

    public getAll(data?: any): Observable<BaseArrayResponseDto<Dto>> {
        return super.getAll({ populate: ['last_updated_by;first_name,full_name,last_name', 'locked_by;first_name,full_name,last_name'], select: ['date_entered, name, status, type'], ...data }); // We don't need to populate status, it will match based on ObjectId as well...and when we change it, the account-status-type will send an object to the back end and update the "status_title" property properly
    }

    public getTax(locationId: string): Observable<Dto['tax']> {
        return this.get(locationId, { select: 'tax' })
            .pipe(
                map((response: Dto) => response.tax),
            );
    }

}
