import { DestroyRef, inject, Pipe, PipeTransform } from '@angular/core';
import { toZonedTime, format as formatTZ } from 'date-fns-tz';
import { Observable, of, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TimezoneService } from 'app/modules/components/timezone/timezone.service';

@Pipe({
    name: 'applyTimezone',
    pure: true,
    standalone: true,
})
export class ApplyTimezonePipe implements PipeTransform {

    private readonly defaultFormat: string = 'M/d/yyyy h:mm a';
    private destroyRef = inject(DestroyRef);

    constructor(
        private _timezoneService: TimezoneService,
    ) { }

    transform(
        value: string | number | Date | null,
        formatStr: string = this.defaultFormat,
        timezone?: string,
        locale?: string
    ): Observable<string | null> {
        if (!value) return of(null);

        return this._timezoneService.userTimezone$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap(userTimezone => {
                    const appliedTimezone = timezone || userTimezone || 'UTC';
                    const date = new Date(value);
                    const zonedDate = toZonedTime(date, appliedTimezone);
                    const formattedDate = formatTZ(zonedDate, formatStr, { timeZone: appliedTimezone });

                    return of(formattedDate);
                })
            );
    }
}
