import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'InsertSpaces'
})
export class InsertSpacesPipe implements PipeTransform {

    constructor() { }

    transform(string, args: string[]): any {
        /* ======================================================
        ACROText -> ACRO Text
        UserNameTest -> User Name Test
        ====================================================== */
        string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
        string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        return string;
    }

}
