import { Pipe, PipeTransform } from '@angular/core';

// import { convertToTimeZone, formatToTimeZone, parseFromTimeZone } from 'date-fns-timezone';

@Pipe({
    name: 'dateFNSUTCToEST'
})
export class DateFNSUTCToESTPipe implements PipeTransform {
    // https://npmdoc.github.io/node-npmdoc-date-fns/build/apidoc.html
    transform(inputDate: any, outputFormat?: any): any {
        if (!outputFormat)
            outputFormat = 'MM/DD/YYYY hh:mm:ss A';

        inputDate = new Date(inputDate);

        const utcToEST = new Date(inputDate.getTime() - (inputDate.getTimezoneOffset() * 60000));

        const date = utcToEST.toISOString().split('T')[0];
        const time = utcToEST.toTimeString().split(' ')[0];

        return `${date} ${time}`;
    }

}
