import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { AuthService } from 'app/core/auth/auth.service';
import { HttpParamsHelper } from './http-params-helper';

import { BaseArrayResponseDto } from '../../../rest-api/src/shared/base/base-array-response.dto';

// TODO - Add global error catcher and display toast

export interface BaseDTO {
    _id: string;
}
@Injectable({
    providedIn: 'root'
})
export abstract class CRUDBaseService<T extends BaseDTO> {

    private _httpClient: HttpClient = inject(HttpClient);
    private _authService: AuthService = inject(AuthService);

    constructor(
        protected apiPrefix: string,
    ) { }

    public create(data: T): Observable<T> {
        return this._httpClient.post<T>(`/${this.apiPrefix}`, data);
    }

    public delete(id: string): Observable<T> {
        return this._httpClient.delete<T>(`/${this.apiPrefix}/${id}`);
    }

    public get(id: string, data?, withAuditHistory = false): Observable<T> {
        if (!withAuditHistory)
            data = { select: '-audit_history', ...data };
        const params = HttpParamsHelper.setHttpParams(data);
        return this._httpClient.get<T>(`/${this.apiPrefix}/${id}`, { params });
    }

    public getAll(data?): Observable<BaseArrayResponseDto<T>> {
        data = data ?? {};
        data['select'] = (data['select'] ? data['select'] : '-audit_history');
        const params = HttpParamsHelper.setHttpParams(data);
        return this._httpClient.get<BaseArrayResponseDto<T>>(`/${this.apiPrefix}`, { params });
    }

    public getAuditHistory(id: string, data?: any): Observable<T> {
        return this.get(id, { select: ['audit_history'], ...data });
    }

    public lock(id: string) {
        return this._httpClient.get(`/${this.apiPrefix}/record_lock/${id}`)
    }

    public search(search_by, query): Observable<T[]> {
        return this._httpClient.post<T[]>(`/${this.apiPrefix}/search`, { search_by, query });
    }

    public unlock(id: string): Observable<T> {
        return this._httpClient.get<T>(`/${this.apiPrefix}/${id}/unlock`);
    }

    public update(data: T): Observable<T> {
        return this._httpClient.patch<T>(`/${this.apiPrefix}/${data._id}`, data);
    }


}
