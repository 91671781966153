// Add provideCheckVersion(), to the providers list in app.config.ts

import { APP_INITIALIZER } from '@angular/core';
import { inject, Provider } from '@angular/core';

import { CheckVersionService } from '@nevtec/modules/check-version/check-version.service';

export const provideCheckVersion = (): Array<Provider> => {
    return [
        {
            provide: APP_INITIALIZER,
            useFactory: (checkVersionService: CheckVersionService) => () => {
                checkVersionService.initialize();
            },
            deps: [CheckVersionService],
            multi: true,
        },
    ];
};
