import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Output, EventEmitter, Renderer2 } from '@angular/core';
import { filter, map, take, tap } from 'rxjs';
import { NavigationEnd, RouteConfigLoadEnd, Router, RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';

import { PassageService } from 'app/passageOLD/passage.service';

@Component({
    selector: 'app-tab-navigation',
    template: `
        <div class="flex justify-between w-full pb-6">
                <div routerLink="/dashboard" routerLinkActive="text-primary" class="w-full flex flex-col items-center pt-2 pb-1">
                <mat-icon svgIcon="mat_solid:space_dashboard" routerLinkActive="text-primary"></mat-icon>
                <span class="text-xs">Dashboard</span>
            </div>
            <div routerLink="/passages" routerLinkActive="text-primary" class="w-full flex flex-col items-center pt-2 pb-1">
                <mat-icon svgIcon="mat_solid:pets" routerLinkActive="text-primary"></mat-icon>
                <span class="text-xs">Passages</span>
            </div>
            <div class="w-full flex flex-col items-center pt-2 pb-1" (click)="goToLastPassage()" *ngIf="passageService.Observables.hasLastPassage$ | async">
                <mat-icon svgIcon="mat_outline:keyboard_return"></mat-icon>
                <span class="text-xs">Last Passage</span>
            </div>
                <div routerLink="/profile" routerLinkActive="text-primary" class="w-full flex flex-col items-center pt-2 pb-1">
                <mat-icon svgIcon="mat_solid:account_box" routerLinkActive="text-primary"></mat-icon>
                <span class="text-xs">Profile</span>
            </div>
        </div>
    `,
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        RouterModule,

        MatIconModule,
    ],
    styles: [`
        :host {
            @apply bg-white bottom-0 fixed inset-x-0 shadow sm:hidden w-full z-10;
        } 
    `],
})
export class TabNavigationComponent {

    constructor(
        private _renderer2: Renderer2,
        private _router: Router,
        public passageService: PassageService,
    ) { }

    public goToLastPassage() {
        this.passageService.Observables.lastPassage$
            .pipe(take(1))
            .subscribe(order_id => this._router.navigateByUrl(`/passage/${order_id}`));
    }

    ngOnInit() {
        const appRoot = document.querySelector('app-root');
        this._renderer2.addClass(appRoot, 'pb-20');
        this._renderer2.addClass(appRoot, 'sm:pb-0');
    }

}
