import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keys',
    standalone: true
})
export class KeysPipe implements PipeTransform {

    constructor() { }

    transform(value, args: string[]): any {
        /* ======================================================
        <span *ngFor="let entry of content | keys">
            Key: {{entry.key}}, value: {{entry.value}}
        </span>
        ====================================================== */

        let keys = [];

        for (let key in value)
            keys.push({ key: key, value: value[key] });

        return keys;
    }

}