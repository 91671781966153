import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'kebab'
})
export class KebabPipe implements PipeTransform {

    transform(value: string): string {
        if (value) {
            return value.replace(/([a-z])([A-Z])/g, '$1-$2')
                .replace(/\s+/g, '-')
                .toLowerCase();
        }
    }

}
