import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addClassToLink'
})
export class AddClassToLinkPipe implements PipeTransform {

    transform(text: string): string {
        // console.log(text);
        const addClassAttributeRegex = /(<a(?:(?!class=).)[^>]*")/g;
        text = text.replace(addClassAttributeRegex, '$1 class=""');
        // console.log(text);
        // (<a[\s]+([^>]+)) will be <a and any other attributes before class - $1
        // *class=""* - $2
        // ((?:.(?!\<\/a\>))*.) the current text of the link, including </a> - $3
        const addClassRegex = /(<a[\s]+([^>]+))*class=""*>((?:.(?!\<\/a\>))*.<\/a>)/g; // /[<a]*(class="([^"]*))/g;
        text = text.replace(addClassRegex, '$1 class="animated-underline inline-block font-semibold">$3');
        // console.log(text);
        return text;

    }

}
