import { animateChild, trigger, transition, query } from '@angular/animations';
import { map, distinctUntilChanged, tap, BehaviorSubject } from 'rxjs';
// https://williamjuan.dev/blog/ionic-bottomsheets-using-css-grid
// https://github.com/williamjuan027/ionic-restaurant-demo

import { Component, Input, TemplateRef, ChangeDetectionStrategy } from '@angular/core';
import { AsyncPipe, CurrencyPipe, NgIf, NgTemplateOutlet } from '@angular/common';

import { FadeAnimation, slideUpAnimation } from '@nevtec/animations';

import { Layers, BottomSheetService } from './bottom-sheet.service';

@Component({
    selector: 'app-bottom-sheet',
    template: `
        <div *ngIf="isOpen$ | async" @container class="h-screen grid grid-rows-1 grid-cols-1 w-full">
            <!-- shade -->
            <div @FadeAnimation class="row-start-1 row-span-1 col-start-1 col-span-1 bg-black bg-opacity-50" (click)="close()">
            </div>

            <!-- bottomsheet -->
            <div @slideUp
                class="row-start-1 row-span-1 col-start-1 col-span-1 z-10 self-end p-5 bg-white rounded-t-xl overflow-scroll max-h-screen relative {{ ( options$ | async )?.height }}">
                <ng-container [ngTemplateOutlet]="currentTemplate$ | async"></ng-container>
            </div>
        </div>
    `,
    animations: [
        FadeAnimation,
        slideUpAnimation,
        // Allows the animation on close for the bottom sheet
        trigger('container', [
            transition(':enter, :leave', [
                query('@*', animateChild(), { optional: true }),
            ]),
        ]),
    ],
    styles: [`
        :host {
            @apply bottom-0 left-0 fixed w-full;
            z-index: 300; // The sidebar is 200
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        CurrencyPipe,
        NgIf,
        NgTemplateOutlet,
    ]
})
export class BottomSheetComponent {
    public currentTemplate$ = this.BottomSheetService.currentTemplate$;
    public isOpen$ = this.BottomSheetService.Observables.layers$
        .pipe(
            map((layers: Layers) => layers.detailsBottomSheet),
            distinctUntilChanged(),
        );
    public options$ = this.BottomSheetService.Observables.options$;



    constructor(
        private BottomSheetService: BottomSheetService,
    ) { }



    public close(): void {
        this.BottomSheetService.close();
    }



}