import { switchMap, shareReplay, tap, map, finalize, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable, inject } from '@angular/core';

import { AppStateService } from '@nevtec/services/app-state.service';

@Injectable({
    providedIn: 'root'
})
export class HttpCacheService {

    protected appStateService = inject(AppStateService);
    private httpCache: { [cacheKey: string]: Observable<any> } = {};
    public loading$ = new BehaviorSubject<boolean>(false);

    constructor() {
        this.appStateService.resetTrigger$
            .subscribe(() => {
                this.clearHttpCache();
            });

        this.appStateService.keyRemovalTrigger$
            .subscribe((cacheKey: string) => {
                this.clearCacheKey(cacheKey);
            });
    }

    public clearHttpCache(): void {
        this.httpCache = {};
    }

    public clearCacheKey(cacheKey: string): void {
        delete this.httpCache[cacheKey];
    }

    public getFromCacheOrFetch(cacheKey: string, fetchFn: () => Observable<any>): Observable<any> {
        if (this.httpCache[cacheKey]) {
            return this.httpCache[cacheKey];
        }

        const appStateData = this.appStateService.get(cacheKey);

        if (appStateData) {
            this.httpCache[cacheKey] = of(appStateData).pipe(shareReplay(1));
        } else {
            this.loading$.next(true);
            this.httpCache[cacheKey] = fetchFn().pipe(
                this.handleLocalCache(cacheKey),
                shareReplay(1),
                finalize(() => {
                    this.loading$.next(false);
                })
            );
        }

        return this.httpCache[cacheKey];
    }

    public handleLocalCache(cacheKey: string) {
        return (source: Observable<any>) => source.pipe(
            tap({
                next: (data: any) => {
                    this.appStateService.set(cacheKey, data);
                },
                error: () => {
                    this.clearCacheKey(cacheKey);
                }
            }),
            take(1)
        );
    }
}
