import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeDash',
    standalone: true,
})
export class RemoveDashPipe implements PipeTransform {
    transform(value: string): string {
        return value ? value.replace(/-/g, ' ') : value;
    }
}