import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { map } from 'rxjs';

import { AppStateService } from '@nevtec/services/app-state.service';

@Component({
    selector: 'dev-mode',
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
    ],
    template: `
        <div *ngIf="isDevMode$ | async" class="bg-red-500 text-white p-2 rounded-lg font-bold">
            Dev Mode
        </div>
    `,
})
export class DevModeComponent {

    public isDevMode$ = this._appStateService.get$('apiURL')
        .pipe(
            map(apiURL => apiURL.includes('dev-')),
        );

    constructor(
        private _appStateService: AppStateService,
    ) { }

}