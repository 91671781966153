import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, pluck } from 'rxjs';

import { AuthUtils } from '../../app/core/auth/auth.utils';
import { UtilitiesService } from '@nevtec/services/utilities.service';

import { CompanySettingsDto } from '../../../rest-api/src/company/dto/company-settings.dto';
import { UserSettingsDto } from './../../../rest-api/src/user/dto/user-settings.dto';

type CompanySettingsDtoKeys = keyof CompanySettingsDto;
type UserSettingsDtoKeys = keyof UserSettingsDto;

@Injectable({
    providedIn: 'root'
})
export class AppStateService {

    private _keyRemovalTrigger = new BehaviorSubject<string>(null);
    public keyRemovalTrigger$ = this._keyRemovalTrigger.asObservable();
    private _resetTrigger = new BehaviorSubject<boolean>(false);
    public resetTrigger$ = this._resetTrigger.asObservable();
    private _state = {};
    private state$ = new BehaviorSubject<any>(this._state);

    // Helpers
    public companySettingsKey = 'companySettings';
    public companySettings$: Observable<CompanySettingsDto> = this.get$(this.companySettingsKey);
    public getCompanySetting$(key: CompanySettingsDtoKeys): Observable<any> {
        return this.companySettings$
            .pipe(
                map((companySettings: CompanySettingsDto) => {
                    return companySettings[key] ?? false;
                }),
            );
    }
    public userSettings$ = this.get$('user').pipe(pluck('settings'));
    public getUserSetting$(key: UserSettingsDtoKeys): Observable<any> {
        return this.userSettings$
            .pipe(
                map((userSettings: UserSettingsDto) => {
                    return userSettings[key] ?? false;
                }),
            );
    }


    constructor(
        private UtilitiesService: UtilitiesService,
    ) {
        this.initialize();
    }



    private initialize() {
        Object.keys(localStorage).forEach(key => {
            const decodedValue = AuthUtils.decode(localStorage[key]);
            if (this.UtilitiesService.isJSON(decodedValue))
                this._state[key] = JSON.parse(decodedValue);
            // else if (!isNaN(parseInt(decodedValue)))
            //     this._state[key] = parseInt(decodedValue);
            else
                this._state[key] = decodedValue;
        })
        this.state$.next(this._state) // inform subscribers
    }



    public get(key: string): any {
        return this._state[key];
    }



    public get$(key: string): Observable<any> {
        return this.state$.pipe(
            map(object => (object[key] || false)),
        );
    }



    public remove(key: string): void {
        delete this._state[key];
        localStorage.removeItem(key);
        this.state$.next(this._state);
        this._keyRemovalTrigger.next(key);
    }



    public reset() { // : Observable<any> {
        this._resetTrigger.next(true);
        Object.keys(localStorage).forEach(key => {
            if (key.indexOf('remember') === -1 && key.indexOf('apiURL') === -1)
                this.remove(key);
        });
    }



    public set(key: string, value: any, persist: boolean = true): void {
        this._state[key] = value; // in Memory
        this.state$.next(this._state); // inform subscribers
        if (persist) {
            if (typeof value === 'object') {
                localStorage[key] = AuthUtils.encode(JSON.stringify(value));
            } else {
                localStorage[key] = AuthUtils.encode(value);
            }
        }
    }



}