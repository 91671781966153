import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
        <tour-step-template></tour-step-template>
    `,
    styles: [`
        :host {
            @apply flex flex-auto h-full w-full;
            /* display: flex;
            flex: 1 1 auto;
            width: 100%;
            height: 100%; */
        }

    `],
    standalone: true,
    imports: [
        RouterOutlet,

        TourMatMenuModule,
    ],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor() {
    }
}
