import { Pipe, PipeTransform } from '@angular/core';

import { extractDeepPropertyByMapKey, isString, isUndefined } from './helpers';


@Pipe({
    name: 'orderBy',
    standalone: true
})
export class OrderByPipe implements PipeTransform {



    /**
     * Sorts values ignoring the case
     *
     * @param a
     * @param b
     */
    static caseInsensitiveSort(a: any, b: any) {
        if (OrderByPipe.isString(a) && OrderByPipe.isString(b)) {
            return a.localeCompare(b);
        }
        return OrderByPipe.defaultCompare(a, b);
    }



    /**
     * Default compare method
     *
     * @param a
     * @param b
     */
    static defaultCompare(a: any, b: any) {
        if (a === b) {
            return 0;
        }
        if (a == null) {
            return 1;
        }
        if (b == null) {
            return -1;
        }
        return a > b ? 1 : -1;
    }



    /**
     * Get value by expression
     *
     * @param object
     * @param expression
     * @returns {any}
     */
    static getValue(object: any, expression: string[]) {
        for (let i = 0, n = expression.length; i < n; ++i) {
            const k = expression[i];
            if (!(k in object)) {
                return;
            }
            object = object[k];
        }

        return object;
    }



    private isDate(input: any) {
        var dateType = /(\d{4})([\/-])(\d{1,2})\2(\d{1,2})/;
        var isMatch = dateType.test(input);
        return isMatch;
    }



    /**
   * Check if a value is a string
   *
   * @param value
   */
    static isString(value: any) {
        return typeof value === 'string' || value instanceof String;
    }



    /**
     * Apply multiple expressions
     *
     * @param value
     * @param {any[]} expressions
     * @param {boolean} reverse
     * @param {boolean} isCaseInsensitive
     * @param {Function} comparator
     * @returns {any}
     */
    private multiExpressionTransform(value: any, expressions: any[], reverse: boolean, isCaseInsensitive: boolean = false, comparator?: Function): any {
        return expressions.reverse().reduce((result: any, expression: any) => {
            return this.transform(result, expression, reverse, isCaseInsensitive, comparator);
        }, value);
    }



    /**
     * Parse expression, split into items
     * @param expression
     * @returns {string[]}
     */
    static parseExpression(expression: string): string[] {
        expression = expression.replace(/\[(\w+)\]/g, '.$1');
        expression = expression.replace(/^\./, '');
        return expression.split('.');
    }



    /**
     * Set value by expression
     *
     * @param object
     * @param value
     * @param expression
     */
    static setValue(object: any, value: any, expression: string[]) {
        let i;
        for (i = 0; i < expression.length - 1; i++) {
            object = object[expression[i]];
        }

        object[expression[i]] = value;
    }



    /**
     * Sort array
     *
     * @param value
     * @param expression
     * @param reverse
     * @param isCaseInsensitive
     * @param comparator
     * @returns {any[]}
     */
    private sortArray(value: any[], expression?: any, reverse?: boolean, isCaseInsensitive?: boolean, comparator?: Function): any[] {
        const isDeepLink = expression && expression.indexOf('.') !== -1;

        if (isDeepLink) {
            expression = OrderByPipe.parseExpression(expression);
        }

        let compareFn: Function;

        if (comparator && typeof comparator === 'function') {
            compareFn = comparator;
        } else {
            compareFn = isCaseInsensitive ? OrderByPipe.caseInsensitiveSort : OrderByPipe.defaultCompare;
        }

        const array: any[] = value.sort((a: any, b: any): number => {
            if (!expression) {
                return compareFn(a, b);
            }

            if (!isDeepLink) {

                if (this.isDate(a[expression]))
                    a[expression] = new Date(a[expression]);
                if (this.isDate(b[expression]))
                    b[expression] = new Date(b[expression]);

                if (a && b) {
                    return compareFn(a[expression], b[expression]);
                }
                return compareFn(a, b);
            }

            return compareFn(OrderByPipe.getValue(a, expression), OrderByPipe.getValue(b, expression));
        });

        if (reverse) {
            return array.reverse();
        }

        return array;
    }



    public transform(value: any | any[], expression?: any, reverse?: boolean, isCaseInsensitive: boolean = false, comparator?: Function): any {
        if (!value)
            return value;

        if (Array.isArray(expression))
            return this.multiExpressionTransform(value, expression, reverse, isCaseInsensitive, comparator);

        if (Array.isArray(value))
            return this.sortArray(value.slice(), expression, reverse, isCaseInsensitive, comparator);

        if (typeof value === 'object')
            return this.transformObject(Object.assign({}, value), expression, reverse, isCaseInsensitive, comparator);

        return value;
    }



    /**
     * Transform Object
     *
     * @param value
     * @param expression
     * @param reverse
     * @param isCaseInsensitive
     * @param comparator
     * @returns {any[]}
     */
    private transformObject(
        value: any | any[],
        expression?: any,
        reverse?: boolean,
        isCaseInsensitive?: boolean,
        comparator?: Function
    ): any {
        const parsedExpression = OrderByPipe.parseExpression(expression);
        let lastPredicate = parsedExpression.pop();
        let oldValue = OrderByPipe.getValue(value, parsedExpression);

        if (!Array.isArray(oldValue)) {
            parsedExpression.push(lastPredicate);
            lastPredicate = null;
            oldValue = OrderByPipe.getValue(value, parsedExpression);
        }

        if (!oldValue)
            return value;

        OrderByPipe.setValue(value, this.transform(oldValue, lastPredicate, reverse, isCaseInsensitive), parsedExpression);
        return value;
    }



}