import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace'
})
export class ReplacePipe implements PipeTransform {

    transform(value: any, FindReplace?: object): any {
        if (value && FindReplace && FindReplace.constructor === Object && Object.keys(FindReplace).length !== 0)
            Object.keys(FindReplace).forEach(key => value = value.replace('$' + key, FindReplace[key])); // If data passed is "lookup_id", we will look for "$lookup_id" in "value", or the paragraph text, sent and replace it
        return value;
    }

}
